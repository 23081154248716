@keyframes SGS0fW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes SGS0fW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.SGS0fW_multilineInput > :not(:first-child) {
  margin-top: 8px;
}

.SGS0fW_multilineInput .SGS0fW_firstFieldWithMultiInputs {
  padding-right: 36px;
}

.SGS0fW_multilineInput .SGS0fW_deletableInput {
  align-items: center;
  display: flex;
}

.SGS0fW_multilineInput .SGS0fW_deletableInput > :first-child {
  flex: 1;
}

.SGS0fW_multilineInput .SGS0fW_deletableInput > :not(:first-child) {
  margin-left: 8px;
}

.SGS0fW_multilineInput .SGS0fW_minusIcon {
  color: var(--color-text-secondary);
}

.SGS0fW_multilineInput .SGS0fW_addAnother {
  margin-top: 4px;
}

.SGS0fW_multilineInput .SGS0fW_error {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 4px;
}

@keyframes _5hpe_G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _5hpe_G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._5hpe_G_headlineWithMultiInputs {
  padding-right: 36px;
}

@keyframes _6CCQEa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _6CCQEa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._6CCQEa_wrapper {
  align-items: flex-start;
  display: flex;
  position: relative;
}

._6CCQEa_wrapper ._6CCQEa_field, ._6CCQEa_wrapper ._6CCQEa_field ._6CCQEa_multiTextInput {
  flex: 1;
}

._6CCQEa_wrapper ._6CCQEa_saveButton {
  flex-shrink: 0;
  margin: 24px 0 0 8px;
}

@keyframes wjiRLq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes wjiRLq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.wjiRLq_container {
  width: 28px;
  height: 28px;
  color: var(--color-text-link);
  background: var(--color-focused-variant);
  font: var(--font-title-2);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.wjiRLq_container.wjiRLq_active {
  color: var(--color-on-primary);
  background: var(--color-primary);
}

.wjiRLq_container.wjiRLq_completed {
  background: var(--color-primary);
}

.wjiRLq_container.wjiRLq_completed > svg {
  color: var(--color-on-primary);
}

@keyframes VqKMoq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes VqKMoq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.VqKMoq_card {
  flex-direction: column;
  padding: 20px 24px;
  scroll-margin: 20px;
  display: flex;
}

.VqKMoq_card .VqKMoq_header {
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.VqKMoq_card .VqKMoq_header > svg {
  color: var(--color-text-secondary);
}

.VqKMoq_card .VqKMoq_header .VqKMoq_index {
  margin-right: 16px;
}

.VqKMoq_card .VqKMoq_buttonWrapper {
  justify-content: flex-end;
  margin-top: 24px;
  display: flex;
}

.VqKMoq_card .VqKMoq_content {
  height: 0;
  margin-top: 0;
  overflow: hidden;
}

.VqKMoq_card .VqKMoq_content.VqKMoq_expanded {
  height: auto;
  overflow: unset;
}

.VqKMoq_card .VqKMoq_content > :first-child {
  margin-top: 24px;
}

.VqKMoq_card li {
  font: var(--font-body-2);
}

.VqKMoq_card li ul, .VqKMoq_card li ol {
  padding-inline-start: 1ch;
}

.VqKMoq_card ul {
  padding-inline-start: 4ch;
}

.VqKMoq_card ul > li {
  margin-block: 8px;
  padding-inline-start: 4px;
}

.VqKMoq_card ol {
  padding-inline-start: 2ch;
}

.VqKMoq_card ol > li {
  margin-block: 12px;
  padding-inline-start: 4px;
}

.VqKMoq_card h3 {
  font: var(--font-title-2);
  color: var(--color-text-secondary);
  margin: 24px 0 12px;
}

.VqKMoq_card p {
  font: var(--font-body-2);
  margin: 12px 0;
}

.VqKMoq_card strong {
  font: var(--font-label-2);
}

.VqKMoq_card pre {
  margin: 12px 0;
}

.VqKMoq_card code:not(pre > code) {
  background: var(--color-layer-2);
  font: var(--font-body-2);
  border-radius: 4px;
  padding: 4px;
}

.VqKMoq_card + .VqKMoq_card {
  margin-top: 24px;
}

@keyframes RdP3iG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes RdP3iG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.RdP3iG_container {
  width: 100%;
  margin-top: 12px;
}

.RdP3iG_container ul {
  border-bottom: 1px solid var(--color-divider);
  margin: 4px 0;
  padding: 0;
  display: flex;
}

.RdP3iG_container ul li {
  font: var(--font-label-2);
  color: var(--color-text-secondary);
  margin-right: 24px;
  padding-bottom: 4px;
  cursor: pointer;
  margin-block-end: unset;
  padding-inline-start: unset;
  list-style: none;
}

.RdP3iG_container ul li[aria-selected="true"] {
  color: var(--color-text-link);
  border-bottom: 2px solid var(--color-text-link);
  outline: none;
  margin-bottom: -1px;
}

.RdP3iG_container .RdP3iG_hidden {
  display: none;
}

@keyframes _p8-jq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _p8-jq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._p8-jq_inlineNotification {
  font: var(--font-body-2);
  border-radius: 8px;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  display: flex;
}

._p8-jq_inlineNotification._p8-jq_shadow {
  border: 1px solid var(--color-border);
  box-shadow: var(--shadow-1);
}

._p8-jq_inlineNotification ._p8-jq_icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

._p8-jq_inlineNotification ._p8-jq_content {
  overflow-wrap: break-word;
  flex: 1;
  overflow: hidden;
}

._p8-jq_inlineNotification._p8-jq_info {
  background: var(--color-info-container);
}

._p8-jq_inlineNotification._p8-jq_info ._p8-jq_icon {
  color: var(--color-on-info-container);
}

._p8-jq_inlineNotification._p8-jq_alert {
  background: var(--color-alert-container);
}

._p8-jq_inlineNotification._p8-jq_alert ._p8-jq_icon {
  color: var(--color-on-alert-container);
}

._p8-jq_inlineNotification._p8-jq_success {
  background: var(--color-success-container);
}

._p8-jq_inlineNotification._p8-jq_error {
  background: var(--color-error-container);
}

/*# sourceMappingURL=ios.dd00bd67.css.map */
